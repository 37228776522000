const getFixDepositAccounts = async (url, vlgId, officeId) => {
    const api = "1P8Rsli9pO5cHoSpyDOeDCLH3nIQTIG85gMfxOXh";
    const username = "thawzintun";
    const password = "99999999";
    const basicAuth = btoa(`${username}:${password}`);

    const filter = {
        filterRulesExpression: {
            condition: "AND",
            rules: [
                {
                    id: "depositType",
                    field: "depositType",
                    type: "integer",
                    input: "select",
                    operator: "equal",
                    value: 200,
                },
                {
                    id: "officeId",
                    field: "officeId",
                    type: "integer",
                    input: "select",
                    operator: "equal",
                    value: officeId,
                },
                {
                    id: "groupId",
                    field: "groupId",
                    type: "integer",
                    input: "select",
                    operator: "equal",
                    value: vlgId,
                },
                {
                    condition: "OR",
                    rules: [
                        {
                            id: "status",
                            field: "status",
                            type: "integer",
                            input: "select",
                            operator: "equal",
                            value: 300,
                        },
                        {
                            id: "status",
                            field: "status",
                            type: "integer",
                            input: "select",
                            operator: "equal",
                            value: 800,
                        },
                    ],
                },
            ],
            valid: true,
        },
        responseParameters: [
            {
                name: "accountNo",
                ordinal: 0,
            },
            {
                name: "clientId",
                ordinal: 1,
            },
            {
                name: "clientName",
                ordinal: 2,
            },
            {
                name: "totalWithdrawals",
                ordinal: 3,
            },
            {
                name: "maturityDate",
                ordinal: 4,
            },
            {
                name: "savingsProductName",
                ordinal: 5,
            },
            {
                name: "activatedOnDate",
                ordinal: 6,
            },
            {
                name: "totalDeposits",
                ordinal: 7,
            },
            {
                name: "clientAccountNo",
                ordinal: 8,
            },
            {
                name: "clientId",
                ordinal: 9,
            },
            {
                name: "nominalAnnualInterestRate",
                ordinal: 10,
            },
            {
                name: "totalInterestEarned",
                ordinal: 11,
            },
            {
                name: "depositPeriod",
                ordinal: 12,
            },
        ],
        sortByParameters: [],
    };

    const options = {
        method: "POST", // You can specify the HTTP method (GET in this case)
        headers: {
            Authorization: "Basic " + basicAuth, // Note the space after "Basic"
            "Content-Type": "application/json",
            "x-api-key": api,
        },
        body: JSON.stringify(filter),
    };

    const data = await fetch(url, options);
    const response = await data.json();

    return response;
};

export default getFixDepositAccounts;
