const calculateFirstInterest = (
    activatedDate,
    month,
    oneDay,
    firstDeposit,
    dailyInterestRate
) => {
    // Convert the input date to a Date object
    const date = new Date(activatedDate);
    const currentMonth = date.getMonth() + month;
    // Find the last day of the month
    const lastDayOfMonth = new Date(date.getFullYear(), currentMonth, 0);
    // Calculate the number of days remaining
    const daysRemaining = Math.ceil((lastDayOfMonth - date) / oneDay) + 1;
    const totalInterest = Number((daysRemaining * firstDeposit * dailyInterestRate).toFixed(8))

    // console.log(currentMonth,lastDayOfMonth,daysRemaining ,totalInterest, firstDeposit);
    return totalInterest;
};

export default calculateFirstInterest;
