const remainingInterest = (
    activatedDate,
    month,
    oneDay,
    totalBalance,
    dailyInterestRate,firstDeposit
) => {
    const date = new Date(activatedDate);
    const currentMonth = date.getMonth() + month;
    // Convert the input date to a Date object
    const firstDayofMonth = new Date(date.getFullYear(), currentMonth - 1);
    // Find the last day of the month
    const lastDayOfMonth = new Date(date.getFullYear(), currentMonth, 0);
    // Calculate the number of days remaining
    const daysRemaining =
        Math.ceil((lastDayOfMonth - firstDayofMonth) / oneDay) + 1;
    
    const totalInterest = Number((daysRemaining * totalBalance * dailyInterestRate).toFixed(8))
    // console.log(firstDayofMonth,currentMonth,lastDayOfMonth,daysRemaining ,totalInterest, firstDeposit);

    return totalInterest;
};

export default remainingInterest;
