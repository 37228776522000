const lastMonthInterest = (
    maturityDate,
    oneDay,
    totalBalance,
    dailyInterestRate,firstDeposit
) => {
    // Convert the input date to a Date object
    const date = new Date(maturityDate);
    // Find the last day of the month
    const firstDayofMonth = new Date(date.getFullYear(), date.getMonth());
    // Calculate the number of days remaining
    const daysRemaining = Math.ceil((date - firstDayofMonth) / oneDay) - 1;
    const totalInterest =Number((daysRemaining * totalBalance * dailyInterestRate).toFixed(8))
    // console.log(firstDayofMonth,daysRemaining ,totalInterest, firstDeposit);
    return totalInterest;
};

export default lastMonthInterest;
