import React from "react";
import { Form, redirect } from "react-router-dom";
import { getEnv } from "../util/auth";
import { Button } from "@mui/material";
import getApiData from "../api/getApiData";
import getFixDepositAccounts from "../api/getFixDepositAccounts";
import calculateFirstInterest from "../util/calculateFirstInterest";
import remainingInterest from "../util/remainingInterest";
import lastMonthInterest from "../util/lastMonthInterest";
import bankersRounding from "bankers-rounding";

const ReportForm = () => {
    return (
        <>
            <Form method="post" className="flex gap-x-5">
                {/* <TextField type="date" name="date" id="date" /> */}

                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        bgcolor: "black",
                        color: "white",
                        ":hover": {
                            bgcolor: "gray",
                        },
                        p: 1,
                    }}
                >
                    Run Report
                </Button>
            </Form>
        </>
    );
};

export default ReportForm;

export const action = async ({ request }) => {
    let token = localStorage.getItem("env");
    // let groupName = localStorage.getItem("vlgName");
    let vlgId = localStorage.getItem("vlgId");
    let officeId = localStorage.getItem("officeId");
    let url = `https://api.live.sing.musoniservices.com/v1/datafilters/savingsaccounts/queries/run-filter?tenantIdentifier=${token}&offset=0&limit=20000`;
    if (
        token ===
        "3323ce38138a11c33172668cfe999a7c6ab611c6ab6e228a5ff3b1f72af34531"
    ) {
        token = "proximityfinance";
        url = `https://api.live.sing.musoniservices.com/v1/datafilters/savingsaccounts/queries/run-filter?tenantIdentifier=${token}&offset=0&limit=20000`;
    }
    const filteredData = await getFixDepositAccounts(url, vlgId, officeId).then(
        (res) => res.pageItems
    );

    // let metaData = `https://api.live.sing.musoniservices.com/v1/metadata/savingsaccounts?tenantIdentifier=${token}`;
    // const response = await getApiData(metaData);
    // console.log(response);

    // // Get Clients from Specific Group
    // let clientUrl = `https://api.live.sing.musoniservices.com/v1/clients?tenantIdentifier=${token}&search=${groupName}`;

    // const clientData = await getApiData(clientUrl).then((res) => res.pageItems);
    // // console.log(clientData);

    // const filteredData =
    //     data &&
    //     data.filter((fix) => {
    //         const newData =
    //             clientData &&
    //             clientData.find((d) => {
    //                 return (
    //                     fix.clientId === d.id
    //                     // (fix.status.matured || fix.status.active)
    //                 );
    //             });
    //         return newData;
    //     });
    // // console.log(filteredData);

    async function fetchData(clientId) {
        const url = `https://api.live.sing.musoniservices.com/v1/clients/${clientId}/identifiers/?tenantIdentifier=proximityfinance`;
        const clientNRC = await getApiData(url).then(
            (res) => res[0].documentKey
        );
        return { clientId, clientNRC };
    }

    async function fetchDataForAllUsers(clients) {
        const promises = clients.map((user) => fetchData(user.clientId));
        const results = await Promise.all(promises);

        return clients.map((user, index) => ({
            ...user,
            clientNRC: results[index].clientNRC,
        }));
    }

    try {
        const response = await fetchDataForAllUsers(filteredData);
        console.log(response);
        return response.map((data) => {
            const oneDay = 24 * 60 * 60 * 1000;
            const firstDeposit = data.totalDeposits;
            const activatedDate = data.activatedOnDate;
            const maturityDate = data.maturityDate;
            const interestRate = data.nominalAnnualInterestRate / 100;
            const dailyInterestRate = interestRate / 365;
            const depositTerm = data.depositPeriod;
            const totalDiffMonths = depositTerm + 1;

            let totalBalance;
            let interest;
            for (let month = 1; month <= totalDiffMonths; month++) {
                if (month === 1) {
                    const firstInterest = calculateFirstInterest(
                        activatedDate,
                        month,
                        oneDay,
                        firstDeposit,
                        dailyInterestRate
                    );

                    totalBalance = firstDeposit + firstInterest
                    interest = bankersRounding(firstInterest /50)*50;
                    // console.log(interest, firstDeposit);
                    

                } else if (month !== 0 && month !== 1 && month !== totalDiffMonths) {
                    const middleInterest = remainingInterest(
                        activatedDate,
                        month,
                        oneDay,
                        totalBalance,
                        dailyInterestRate,firstDeposit
                    );

                    totalBalance = totalBalance + middleInterest
                    interest = bankersRounding(middleInterest /50)*50 + interest
                    

                } else if (month === totalDiffMonths) {
                    const lastInterest = lastMonthInterest(
                        maturityDate,
                        oneDay,
                        totalBalance,
                        dailyInterestRate,firstDeposit
                    );
                    interest = bankersRounding(lastInterest /50)*50 + interest
                    // console.log(interest, firstDeposit);
                }
            }
            // console.log(totalBalance);
            // totalBalance =  Math.round((totalBalance /50))*50
            // console.log(totalBalance);
            // console.log(interest);
            totalBalance = firstDeposit + interest;
            // const totalInterest =
            //     Math.round(
            //         parseInt(firstDeposit * dailyInterestRate * totalDiffDays) /
            //             50
            //     ) * 50;

            return {
                clientId: data.clientId,
                clientName: data.clientName,
                clientNRC: data.clientNRC,
                accountNo: data.accountNo,
                depositProductName: data.savingsProductName,
                // value: data.status.value,
                activatedOnDate: new Date(
                    data.activatedOnDate
                ).toLocaleDateString("en-GB"),
                maturityDate: new Date(data.maturityDate).toLocaleDateString(
                    "en-GB"
                ),
                totalDeposits: data.totalDeposits,
                returnAmt: totalBalance - firstDeposit,
                expSavReturnManual: totalBalance,
                signature: "",
            };
        });
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    return null;
};

export const loader = async () => {
    let token = getEnv();
    if (!token) {
        return redirect("/env");
    }
    return null;
};
