import React, { useState } from "react";
import { getEnv } from "../util/auth";
import { Form, redirect, useLoaderData, useNavigation } from "react-router-dom";
import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import getApiData from "../api/getApiData";

const OfficeCheckForm = () => {
    const data = useLoaderData();
    const [officeId, setOfficeId] = useState("");
    const { state } = useNavigation();
    const handleChange = (e) => {
        setOfficeId(e.target.value);
    };

    return (
        <>
            <div className="bg-white border border-gray-200 p-10 space-y-6 rounded min-w-[23%]">
                <h3 className="text-xl font-semibold">
                    Please Select Your Branch
                </h3>
                <Form method="post" className="grid grid-flow-row gap-y-3">
                    <FormControl fullWidth>
                        <InputLabel id="office">Select Branch</InputLabel>
                        <Select
                            labelId="office"
                            id="office"
                            label="Select Bnch"
                            name="office"
                            value={officeId}
                            onChange={handleChange}
                            required
                        >
                            {data.map((data) => {
                                if (
                                    data.name !== "Staff Loan" &&
                                    data.name !== "Head Office"
                                ) {
                                    return (
                                        <MenuItem key={data.id} value={data.id}>
                                            {data.name}
                                        </MenuItem>
                                    );
                                }
                                return null;
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        sx={{
                            bgcolor: "black",
                            color: "white",
                            ":hover": {
                                bgcolor: "gray",
                            },
                        }}
                        type="submit"
                        variant="contained"
                    >
                        Continue
                    </Button>
                    {/* <select
                    name="office"
                    id="office"
                    className="border rounded border-gray-400 mb-2 px-2 py-1"
                    required
                >
                    <option value="">Select Branch</option>
                    {data.map((data) => {
                        return (
                            <option key={data.id} value={data.id}>
                                {data.name}
                            </option>
                        );
                    })}
                </select> */}
                    {/* <button className="border bg-black text-white rounded hover:opacity-50 active:opacity-75 py-1 px-2 mb-2">
                    Continue
                </button> */}
                </Form>
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    height: "100vh",
                }}
                open={state === "loading"}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default OfficeCheckForm;

export const action = async ({ request, params }) => {
    const officeData = await request.formData();
    const officeId = officeData.get("office");
    localStorage.setItem("officeId", officeId);
    const expDate = new Date();
    expDate.setHours(expDate.getHours() + 1);
    localStorage.setItem("exp", expDate.toISOString());
    return redirect("/vlg");
};

export const loader = async ({ request, params }) => {
    let token = getEnv();
    if (!token) {
        return redirect("/env");
    }

    let url = `https://api.live.sing.musoniservices.com/v1/offices?tenantIdentifier=${token}&orderBy=name`;
    if (
        token ===
        "3323ce38138a11c33172668cfe999a7c6ab611c6ab6e228a5ff3b1f72af34531"
    ) {
        token = "proximityfinance";
        url = `https://api.live.sing.musoniservices.com/v1/offices?tenantIdentifier=${token}&orderBy=name`;
    }

    const officeData = await getApiData(url);

    return officeData;
};
